<template>
  <div>
    <!--  Selected search criteria and,  selected Search Criteria-->
    <vx-card class="mb-8">
      <div class="flex mb-2 btn-search justify-start flex-wrap items-center">
        <p>Current Connection : {{ connectionID?connectionID.Value:'' }}</p>
        <vs-button
          @click="connectionPopup = true"
          color="success"
          class="sm:mt-0 mt-2 sm:ml-2 ml-0"
          icon-pack="feather"
          >Change Connection
        </vs-button>
      </div>
      <div
        class="
          card-title
          btn-search
          more-card-title
          flex
          justify-between
          items-center
        "
      >
        <div class="flex items-center flex-wrap">
          <h2 class="w-auto">Selected Criteria</h2>
          <p class="table-data-entries-details text-left">
            Total Companies:
            <span>{{ totalCompanies }}</span>
          </p>
        </div>
        <!-- Clear Selected search criteria and Contacts -->
        <vs-button
          @click="clearSearchCriteria()"
          color="grey"
          type="border"
          class="grey-btn sm:mt-0 mt-2"
          icon-pack="feather"
          icon="icon-refresh-ccw"
          >{{ LabelConstant.buttonLabelClearSearchCriteria }}
        </vs-button>
      </div>
      <!-- Selected Search Criteria -->
      <div class="vx-row find-people">
        <div class="vx-col w-full">
          <input-tag v-model="tags" @input="testUpdateSelectedTags"></input-tag>
        </div>
      </div>
    </vx-card>
    <vx-card>
      <div class="vx-row find-people">
        <!-- Search by criteria -->
        <div class="vx-col xl:w-1/4 col-cus-12 mb-8 w-full">
          <!-- Search Criteria -->
          <div class="px-0 lead-accordion z-0 search-filters">
            <div class="filter-card border-0">
              <div slot="header" class="filter-header pl-0">
                Filter Companies by
              </div>
              <div class="filter-list label-mt-2">
                <vs-collapse accordion class="p-0">
                  <!-- System Defined Filters  -->
                  <div class="filter-card px-0">
                    <vs-collapse-item>
                      <div slot="header">System Defined Filters</div>
                      <div  v-for="(tr, indextr) in systemFilterFields" :key="indextr">
                                <label>{{ tr.Display_Name }}</label>
                      <v-select v-if="tr.Search_Values" v-model="tr.searchCompanyValue" :options="tr.Search_Values" multiple class="multiple-select mt-0"
                      :reduce="(option) => option.Value"
                      @input="searchCriteriaChange()" label="Value" />
                        <vs-input  class="w-full mb-2" v-else v-model="tr.searchCompanyValue" @blur="searchCriteriaChange()" />
                      </div>
                    </vs-collapse-item>
                  </div>
                  <!-- CRM Fields  -->
                  <div class="filter-card px-0">
                    <vs-collapse-item>
                      <div slot="header">CRM Fields</div>
                      <div class="filter-list" v-for="(tr, cff) in  crmFilterFields" :key="cff">
                      <label  >{{ tr.Display_Name }}</label>
                      <vs-input class="w-full mb-2" v-if="!tr.Search_Values" v-model="tr.searchCompanyValue" @blur="searchCriteriaChange()" />
                   
                        <v-select
                          multiple
                          v-if="tr.Search_Values"
                          v-model="tr.searchCompanyValue" 
                          :reduce="(option) => option.Value"
                          :options="tr.Search_Values"
                          class="multiple-select mt-0"
                          label="Value" 
                          @input="searchCriteriaChange()"
                        />
                      </div>
                    </vs-collapse-item>
                  </div>

                  <!-- Firmagraphic  -->
                  <!-- <div class="filter-card px-0">
                    <vs-collapse-item>
                      <div slot="header">
                        {{ LabelConstant.headingCollapsableLabelFirmographic }}
                      </div>
                      <div class="filter-list">
                        <vs-checkbox v-model="showCompanyNameText">{{
                          LabelConstant.inputLabelName
                        }}</vs-checkbox>
                        <div v-if="showCompanyNameText">
                          <input-tag></input-tag>
                        </div>
                        <vs-checkbox v-model="showCompanyWebAddressText">{{
                          LabelConstant.inputLabelWebsite
                        }}</vs-checkbox>
                        <div v-if="showCompanyWebAddressText">
                          <input-tag></input-tag>
                        </div>
                        <vs-checkbox v-model="showCompanyIndustryOptions">{{
                          LabelConstant.inputLabelIndustry
                        }}</vs-checkbox>
                        <div v-if="showCompanyIndustryOptions">
                          <v-select
                            class="multiple-select type-multiselect"
                            :label="'Value'"
                            v-model="companyIndustry"
                            multiple
                          />
                        </div>
                        <vs-checkbox v-model="showCompanySICSOptions">
                          {{ LabelConstant.inputLabelSICCode }}
                        </vs-checkbox>
                        <div v-if="showCompanySICSOptions">
                          <v-select
                            class="multiple-select type-multiselect"
                            :label="'Value'"
                            :options="companySICSOptions"
                            v-model="companySICS"
                            multiple
                          />
                        </div>
                        <vs-checkbox v-model="showCompanyNAICSOptions">{{
                          LabelConstant.inputLabelNAICSCode
                        }}</vs-checkbox>
                        <div v-if="showCompanyNAICSOptions">
                          <v-select
                            class="multiple-select type-multiselect"
                            :label="'Value'"
                            :options="companyNAICSOptions"
                            v-model="companyNAICS"
                            multiple
                          />
                        </div>
                        <vs-checkbox v-model="showCompanyEmployeeSizeOptions">{{
                          LabelConstant.inputLabelEmployeeSize
                        }}</vs-checkbox>
                        <div v-if="showCompanyEmployeeSizeOptions">
                          <v-select
                            class="multiple-select type-multiselect"
                            :label="'Value'"
                            :options="companyEmployeeSizeOptions"
                            v-model="companyEmployeeSize"
                            multiple
                          />
                        </div>
                        <vs-checkbox v-model="showCompanyRevenueBandsOptions">{{
                          LabelConstant.inputLabelRevenueBand
                        }}</vs-checkbox>
                        <div v-if="showCompanyRevenueBandsOptions">
                          <v-select
                            class="multiple-select type-multiselect"
                            :label="'Value'"
                            :options="companyRevenueBandsOptions"
                            v-model="companyRevenueBands"
                            multiple
                          />
                        </div>
                      </div>
                    </vs-collapse-item>
                  </div> -->

                  <!-- Company -->
                  <!-- <div class="filter-card px-0">
                    <vs-collapse-item>
                      <div slot="header">Location</div>
                      <div class="filter-list">
                        <vs-checkbox v-model="showContactZipCode">
                          Zip
                        </vs-checkbox>
                        <div v-if="showContactZipCode">
                          <input-tag
                            v-model="contactZipCode"
                            :addTagOnBlur="true"
                          ></input-tag>
                        </div>
                        <vs-checkbox v-model="showContactCity"
                          >City
                        </vs-checkbox>
                        <div v-if="showContactCity">
                          <input-tag
                            v-model="contactCity"
                            :addTagOnBlur="true"
                          ></input-tag>
                        </div>
                        <vs-checkbox v-model="showContactSatesOptions"
                          >State</vs-checkbox
                        >
                        <div v-if="showContactSatesOptions">
                          <v-select
                            class="multiple-select type-multiselect"
                            :label="'Value'"
                            :options="contactStatesOptions"
                            v-model="contactStates"
                            multiple
                          />
                        </div>
                        <vs-checkbox v-model="showContactCountriesOptions"
                          >Country</vs-checkbox
                        >
                        <div v-if="showContactCountriesOptions">
                          <v-select
                            class="multiple-select type-multiselect"
                            :label="'Value'"
                            :options="contactCountriesOptions"
                            v-model="contactCountries"
                            multiple
                          />
                        </div>
                      </div>
                    </vs-collapse-item>
                  </div> -->

                  <!-- Type Criteria -->
                  <!-- <div class="filter-card px-0">
                    <vs-collapse-item>
                      <div slot="header">Type</div>
                      <div class="filter-list">
                        <vs-checkbox v-model="showSuppressionContactTitles"
                          >Type</vs-checkbox
                        >
                        <div v-if="showSuppressionContactTitles">
                          <input-tag
                            v-model="suppressionContactTitles"
                            :addTagOnBlur="true"
                          ></input-tag>
                        </div>
                      </div>
                    </vs-collapse-item>
                  </div> -->
                  <!-- Sync Status -->
                  <!-- <div class="filter-card px-0">
                    <vs-collapse-item>
                      <div slot="header">Sync Status</div>
                      <div class="filter-list">
                        <vs-checkbox >Status</vs-checkbox
                        >
                      </div>
                    </vs-collapse-item>
                  </div> -->
                </vs-collapse>
              </div>
            </div>
          </div>
          <div class="pt-5 mt-5 flex flex-wrap  items-center">
            <vs-button
              title="Filter"
              color="primary"
              type="filled"
              class="mr-3 float-left flex-1 px-4 my-2"
              @click="searchCompanies(1)"
              >Filter
            </vs-button>
            <vs-button
              color="grey"
              type="border"
              class="grey-btn ml-2 float-left flex-1 px-4 my-2"
              @click="clearSearchCriteria()"
              >Clear
            </vs-button>
          </div>
        </div>
        <!--  Contacts table -->
        <div class="vx-col xl:w-3/4 col-cus-12 mb-8 w-full border-left">
          <div class="card-title more-card-title flex align-center">
            <div>
              <h2 class="mb-1">Target Companies</h2>
              <p class="lg:mb-0 sm:mb-2">
                {{ LabelConstant.infoLableRefineYourSearch }}
              </p>
            </div>
            <div>
              <!-- Record Per page -->
              <v-select class="mr-2 float-left show-tbl-data" label="text" :options="recordList" v-model="numberOfRecords" :reduce="options => options.value" :clearable="false" @input="searchCompanies(1)"/>
              <vs-button
                title="Sync"
                color="primary"
                type="filled"
                class="mr-2 float-left"
                @click="targetCompanySync()"
                >Quick Sync</vs-button
              >
              <vs-button
                title="Remove"
                color="warning"
                type="filled"
                class="mr-2 float-left"
                @click="warningPopup = true"
              >
                Remove</vs-button
              >
              <!-- <vs-button
                title="Disqualify"
                color="danger"
                type="filled"
                class="mr-2 float-left"
                @click="(warningType = 'disqualify'), (warningPopup = true)"
                >Disqualify
              </vs-button> -->
            </div>
          </div>

          <!-- Companies table  -->
          <div class="common-table pt-0">
            <!-- table -->
            <vs-table
              v-model="rowsSelected"
              multiple
              :data="targetCompanies"
            >
              <template slot="thead">
                <vs-th></vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Domain</vs-th>
                <vs-th>Type</vs-th>
                <vs-th>Sync Status</vs-th>
                <vs-th>Last Modified</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td width="50">
                    <div class="">
                      <vs-button
                        title="Edit"
                        color="primary"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-edit"
                        class="mr-2 float-left"
                      ></vs-button>
                    </div>
                  </vs-td>
                  <vs-td :data="data[indextr].Company_Name">
                    <a @click="getTargetCompaniesDetails(tr.VisualVisitorID)">
                      {{ data[indextr].Company_Name }}
                    </a>
                  </vs-td>
                  <vs-td :data="data[indextr].Domain">
                    {{ data[indextr].Domain }}
                  </vs-td>
                  <vs-td :data="data[indextr].Type">
                    {{ data[indextr].Type }}
                  </vs-td>
                  <vs-td :data="data[indextr].Sync_Status">
                    {{ data[indextr].Sync_Status }}
                  </vs-td>
                  <vs-td :data="data[indextr].Last_Modified">
                    {{ data[indextr].Last_Modified | formatDate }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <div class="vx-row">
              <div class="vx-col w-full lg:w-full">
                <div class="flex items-center justify-end paginate-row flex-wrap">
                  <paginate
                    ref="companiesPaginate"
                    :page-count="totalPages"
                    :click-handler="searchCompanies"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :page-range="3"
                    :first-last-button="true"
                    :first-button-text="'|<'"
                    :last-button-text="'>|'"
                    :container-class="'paginate'"
                  ></paginate>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
    <!-- select connection popup -->
    <vs-popup class="delete-popup" :active.sync="connectionPopup">
      <template>
        <h2 class="mt-5">Target Company</h2>
        <form data-vv-scope="updateConnection">
        <div class="vx-row my-5 px-5 text-left">
          <div class="vx-col w-full">
            Select CRM Connection to view
            <v-select
            v-model="connectionID"
            :options="connectionList"
            v-validate="'required'"
            name="connectionID"
            label="Value"
            :clearable="false"
             />
          </div>
           <span class="text-danger error-msg">{{
                errors.first("updateConnection.connectionID")
              }}</span>
        </div>
          </form>
      </template>
      <div class="text-center my-6 py-5 ">
        <vs-button title="Update" color="primary" type="filled" class="mr-2"
          @click="updateConnectionId()">{{ LabelConstant.buttonLabelUpdate }}</vs-button
        >
        <vs-button
          color="grey"
          @click="connectionPopup = false"
          type="border"
          class="ml-4 grey-btn mr-3"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
    <!-- Warning popup -->
    <vs-popup class="delete-popup" :active.sync="warningPopup">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>All contacts associated with the company will also be removed.  Are you sure you wish to continue?</h4>
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button
          title="Delete"
          color="danger"
          type="filled"
          class="mr-2"
          @click="targetCompanyRemove()"
          >{{ LabelConstant.buttonLabelDelete }}</vs-button
        >
        <vs-button
          color="grey"
          @click="warningPopup = false"
          type="border"
          class="ml-4 grey-btn mr-3"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
    <!-- Account Details details popup -->
    <vs-popup
      class="campaign-second popup-width"
      title="Company Details"
      :active.sync="companydetailsPopup"
    >
      <template>
          <!--Company Detail  -->
          <div class="text-right w-full float-left  mb-8">
              <vs-button
                title="Update"
                color="primary"
                type="filled"
                @click="goToTargetContact()"
                >Target Contact List</vs-button
              >           
            </div>
            <vx-card class="job-details-tabs">
              <div class="vx-row find-people">
                <div class="vx-col xl:w-full col-cus-12 mb-6 w-full">
                  <div class="card-title flex justify-start items-center mb-2">
                    <h4 class="m-0 pr-1">Company Name:</h4>
                    <p>{{ targetComapnyName }}</p>
                  </div>

                  <div class="table-responsive">
                    <!-- table -->
                    <table
                      class="
                        comparision-table
                        white-background
                        table
                        w-full
                        bordered
                      "
                      v-if="companyDetailsFields.length"
                    >
                      <thead>
                        <vs-th></vs-th>
                        <vs-th colspan="2"> <h2>VisualVisitor</h2></vs-th>
                        <vs-th colspan="3"> <h2>{{ systemName }}</h2></vs-th>
                      </thead>
                      <thead>
                        <vs-th></vs-th>
                        <vs-th>Field Name</vs-th>
                        <vs-th>Value</vs-th>
                        <vs-th>Issue</vs-th>
                        <vs-th>Field</vs-th>
                        <vs-th>Value</vs-th>
                      </thead>
                      <tbody>
                        <tr v-for="(tr, indextr) in companyDetailsFields" :key="indextr" :data="tr"  :class="tr.Issue?'invalid-data':''">
                          <td class="w-10">
                            <div class="table-action w-10">
                                <vs-button
                                v-if="tr.Issue"
                                title="Edit"
                                color="primary"
                                type="filled"
                                icon-pack="feather"
                                icon="icon-edit"
                                class="mr-1"
                                @click="editRow(indextr)"
                              ></vs-button>
                            </div>
                          </td>
                          <td :class="tr.Read_Only?'light-gray':''">{{ tr.App_Display_Field_Name }}</td>
                          <td :class="tr.Read_Only?'light-gray':''"><vs-input v-if="!tr.disableField"   v-model="tr.App_Value" class="text-danger" :disabled="tr.disableField"  /> <p v-else>{{ tr.App_Value }}</p></td>
                          <td class="text-danger">{{ tr.Issue }}</td>
                          <td class="bg-fourth">{{ tr.CRM_Display_Field_Name }}</td>
                          <td class="bg-fourth">{{ tr.CRM_Value }}</td>
                        </tr>
                      </tbody>
                    </table>
                      <!-- <vs-table :data="companyDetailsFields"  class="
                        comparision-table
                        white-background
                        table
                        w-full
                        bordered
                      ">
                        <template slot="thead">
                           <vs-th></vs-th>
                            <vs-th colspan="2"> <h2>VisualVisitor</h2></vs-th>
                            <vs-th colspan="3"> <h2>{{ systemName }}</h2></vs-th>
                      
                        </template>
                          <template slot="thead">
                       
                        </template>
   
                        <template slot-scope="{ data }">
                          <vs-tr>
                               <vs-th></vs-th>
                        <vs-th>Field Name</vs-th>
                        <vs-th>Value</vs-th>
                        <vs-th>Issue</vs-th>
                        <vs-th>Field</vs-th>
                        <vs-th>Value</vs-th>
                      
                          </vs-tr>
                          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                              <vs-td class="w-10">
                            <div class="table-action w-10">
                                <vs-button
                                v-if="tr.Issue"
                                title="Edit"
                                color="primary"
                                type="filled"
                                icon-pack="feather"
                                icon="icon-edit"
                                class="mr-1"
                                @click="editRow(indextr)"
                              ></vs-button>
                            </div>
                          </vs-td>
                          <vs-td :class="tr.Read_Only?'light-gray':''">{{ tr.App_Display_Field_Name }}</vs-td>
                          <vs-td :class="tr.Read_Only?'light-gray':''"><vs-input v-if="!tr.disableField"   v-model="tr.App_Value" class="text-danger" :disabled="tr.disableField"  /> <p v-else>{{ tr.App_Value }}</p></vs-td>
                          <vs-td class="text-danger">{{ tr.Issue }}</vs-td>
                          <vs-td class="bg-fourth">{{ tr.CRM_Display_Field_Name }}</vs-td>
                          <vs-td class="bg-fourth">{{ tr.CRM_Value }}</vs-td>
                          </vs-tr>
                          </template>
                      </vs-table> -->
                  </div>
                </div>
              </div>
            </vx-card>
            <div class="text-right w-full float-left mt-6 mb-8">
            <vs-button
                title="Validate"
                color="warning"
                type="filled"
                class="mr-2"
                @click="validateCompanyDetails()"
                >Validate</vs-button
              >
              <vs-button
                title="Update"
                color="primary"
                type="filled"
                class="mr-2"
                @click="updateCompanyDetails()"
                >{{ LabelConstant.buttonLabelUpdate }}</vs-button
              >
              <vs-button
                title="Sync Now"
                color="success"
                type="filled"
                class="mr-2"
                @click="syncNowCompanyDetails()"
                >Sync Now</vs-button
              >
              <vs-button
                color="grey"
                @click="cancelCompanyDetails()"
                type="border"
                class="grey-btn mr-3"
                >{{ LabelConstant.buttonLabelCancel }}</vs-button
              >
            </div>
          <!-- Target Contact List -->
      </template>
    </vs-popup>
  </div>
</template>
<script >
import Vue from "vue";
import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);
import InputTag from "vue-input-tag";
import { BMedia, BImg, BCardText } from "bootstrap-vue";
import vSelect from "vue-select";
import DateRangePicker from "../../components/filter/DateRangePicker";
import moment from "moment";
import {
    Validator
} from "vee-validate";
const dict = {
    custom: {
        connectionID: {
            required: "Please select connection"
        },
    }
};
Validator.localize("en", dict);

export default {
  components: {
    InputTag,
    BMedia,
    BImg,
    BCardText,
    "v-select": vSelect,
    DateRangePicker,
  },
  filters: {
    formatDate(val) {
      return moment(val).format("YYYY-MM-DD HH:mm A");
    },
  },
  data(){
    return {
    connectionPopup: false,
    companydetailsPopup: false,
    connectionList:[],
    connectionID:null,
    companyDetailsFields:[],
    systemName:null,
    targetComapnyName:null,
    VVID:null,
    systemFilterFields:[],
    crmFilterFields:[],
    editRowIndex:-1,
    numberOfRecords:10,
    recordList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "25", value: 25 },
      { text: "50", value: 50 },
      { text: "100", value: 100},
      { text: "500", value: 500 },
      { text: "1000", value: 1000 },
    ],
    totalPages:1,
    totalCompanies:0,

    rowsSelected: [],
    warningPopup: false,

    selected: "Leanne",
    options: ["Leanne", "Bret", "baz"],
    targetCompanies: [],
    tagsTempArr: [],
    tags:[],
    }
  },
  mounted() {
    this.getTargetConnectionIds();
      if(this.$route.query.conId){
        this.connectionPopup = false;   
    }
    //this.getTargetCompanies();
  },
  methods: {
    updateConnectionId(){
      this.$validator.validateAll("updateConnection").then(async result => {
        if (result) {
          this.getSearchFilters();
          this.connectionPopup=false
        }
      });
    },
    searchCriteriaChange(){
       let arr =[];
        for (let i = 0; i < this.crmFilterFields.length; i++) {
            if(this.crmFilterFields[i].searchCompanyValue){
              if(this.crmFilterFields[i].Search_Values){
                for (let j = 0; j < this.crmFilterFields[i].searchCompanyValue.length; j++) {
                  arr.push(this.crmFilterFields[i].Display_Name +": " + this.crmFilterFields[i].searchCompanyValue[j]);
                }
              } else {
                 arr.push(this.crmFilterFields[i].Display_Name +": " + this.crmFilterFields[i].searchCompanyValue);
              }
            }
          }
           for (let i = 0; i < this.systemFilterFields.length; i++) {
              if(this.systemFilterFields[i].searchCompanyValue){
              if(this.systemFilterFields[i].Search_Values){
                for (let j = 0; j < this.systemFilterFields[i].searchCompanyValue.length; j++) {
                  arr.push(this.systemFilterFields[i].Display_Name +": " + this.systemFilterFields[i].searchCompanyValue[j]);
                }
              } else {
                 arr.push(this.systemFilterFields[i].Display_Name +": " + this.systemFilterFields[i].searchCompanyValue);
              }
            }
           }
          this.tags = arr;
          this.tagsTempArr = arr;
    },
    testUpdateSelectedTags(value){
        let keyArr = this.tagsTempArr.filter((x) => value.indexOf(x) === -1);
        let testStr = keyArr[0];
        let keyStr = keyArr[0].substring(keyArr[0].indexOf(":") + 1);
        this.systemFilterFields.forEach(element => {
           if(element.searchCompanyValue){
             if(testStr.includes(element.Display_Name+":")){
               if(element.Search_Values){
                const index = element.searchCompanyValue.indexOf(keyStr.trim());
              // const index  = element.searchCompanyValue.findIndex(el => el.Value == keyStr.trim());
                element.searchCompanyValue.splice(index, 1);
               } else {
                 element.searchCompanyValue = "";
               }
             }
           }
        });
         this.crmFilterFields.forEach(element => {
           if(element.searchCompanyValue){
             if(testStr.includes(element.Display_Name+":")){
               if(element.Search_Values){
                const index = element.searchCompanyValue.indexOf(keyStr.trim());
                // const index  = element.searchCompanyValue.findIndex(el => el.Value == keyStr.trim());
                element.searchCompanyValue.splice(index, 1);
               } else {
                 element.searchCompanyValue = "";
               }
             }
           }
        });
    },
    async getTargetConnectionIds() {
      this.$vs.loading();
      await this.axios
        .get("/ws/TargetCompanyList/GetConnectionIds")
        .then((response) => {
          let data = response.data;
          this.connectionList = data;
          if(data.length > 1){
             if(!this.$route.query.conId){
              this.connectionPopup = true;
            } else {
               this.connectionID = this.connectionList.find(element => element.Key == this.$route.query.conId);
              this.getSearchFilters();
            }
          } else {
            if(this.$route.query.conId){
               this.connectionID = this.connectionList.find(element => element.Key == this.$route.query.conId);
            } else {
              this.connectionID = data[0];
            }
              this.getSearchFilters();
          //  this.getTargetCompanies();

          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async getTargetCompanies() {
      this.$vs.loading();
      await this.axios
        .get("/ws/TargetCompanyList/GetCompanies?connection_Id="+this.connectionID.Key)
        .then((response) => {
          let data = response.data;
          this.targetCompanies = data.Companies;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
     async getTargetCompaniesDetails(id) {
      this.$vs.loading();
      await this.axios
        .get("/ws/TargetCompanyListDetail/GetTargetCompany?visualVisitorID="+id)
        .then((response) => {
          let data = response.data;
         // console.log(data);
          data.JobActivity.map(function (cdf) {
            cdf.disableField = true;
            return cdf;
          });
          this.companyDetailsFields = data.JobActivity;
          this.systemName = data.CRM_Name_Text;
          this.targetComapnyName = data.Target_Name;
          this.VVID = data.VisualVisitorID;
          this.companydetailsPopup = true;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async targetCompanySync() {
      let Ids = [];
      this.rowsSelected.forEach(element => {
        Ids.push(element.VisualVisitorID);
      });
      let input = {
        VisualVisitorID:Ids
      };
      this.$vs.loading();
      await this.axios
        .post("/ws/TargetCompanyList/SyncCompanies", input)
        .then((response) => {
          let data = response.data;
          this.$vs.notify({
            title: "Success",
            text: data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.searchCompanies(1);
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        });
    },
    async targetCompanyRemove() {
        let Ids = [];
      this.rowsSelected.forEach(element => {
        Ids.push(element.VisualVisitorID);
      });
      let input = {
        Connection_Id:this.connectionID.Key,
        VisualVisitorID:Ids
      };
      this.$vs.loading();
      await this.axios
        .post("/ws/TargetCompanyList/DeleteCompanies", input)
        .then((response) => {
          this.warningPopup = false;

          let data = response.data;
          this.$vs.notify({
            title: "Success",
            text: data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.searchCompanies(1);
          //this.getTargetCompanies();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        });
    },
    /*async targetCompanyDisqualify() {
       let Ids = [];
      this.rowsSelected.forEach(element => {
        Ids.push(element.VisualVisitorID);
      });
      let input = {
        VisualVisitorID:Ids
      };
      this.$vs.loading();
      await this.axios
        .post("/ws/TargetCompanyList/DisqualifyCompanies", input)
        .then((response) => {
          let data = response.data;
          this.warningPopup = false;
          this.warningType = null;
          this.$vs.notify({
            title: "Success",
            text: data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.searchCompanies(1);
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        });
    },*/
    async getSearchFilters() {
      this.$vs.loading();
      await this.axios
        .get("/ws/TargetCompanyList/GetFilters?connection_Id="+this.connectionID.Key)
        .then((response) => {
          let data = response.data;
         // console.log(data);
          data.SystemSearchFields.map(obj => {
            if(obj.Search_Values){
             obj.searchCompanyValue = null;
            } else {
               if(this.$route.query.compId && obj.Display_Name == 'Internal Company Id'){
                obj.searchCompanyValue = this.$route.query.compId.toString();
              } else {
                obj.searchCompanyValue = null;
              }
            }
            return obj;
          });
          this.systemFilterFields = data.SystemSearchFields;
           data.CompanySearchFields.map(obj => {
             if(obj.Search_Values){
              obj.searchCompanyValue = null;
            } else {
             obj.searchCompanyValue = null;
            }
            return obj;
          });
          this.crmFilterFields = data.CompanySearchFields;
          this.searchCriteriaChange();
          this.searchCompanies(1);
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async searchCompanies(pageNumber){
      let systemSearch = [];
      this.systemFilterFields.forEach(element => {
        if(element.searchCompanyValue){
          systemSearch.push({"Field_Id": element.Field_Id,
        "Search_Values": element.Search_Values?element.searchCompanyValue:element.searchCompanyValue?[element.searchCompanyValue]:[],
        });
        }
      });
      let companySearch = [];
       this.crmFilterFields.forEach(element => {
         if(element.searchCompanyValue){
        companySearch.push({"Field_Id": element.Field_Id,
      "Search_Values": element.Search_Values?element.searchCompanyValue:element.searchCompanyValue?[element.searchCompanyValue]:[]
      });
         }
       });
      let input = {
        "Page": pageNumber,
        "Rows_Per_Page": this.numberOfRecords,
        "Connection_Id": this.connectionID.Key,
        SystemSearchFields:systemSearch.length?systemSearch:null,
        CompanySearchFields: companySearch.length?companySearch:null,
        ContactSearchFields:null,
      };
      this.$vs.loading();
      await this.axios
        .post("/ws/TargetCompanyList/SearchCompanies", input)
        .then((response) => {
          let data = response.data;
          this.targetCompanies = data.Companies;
         this.totalPages = data.Page_Count;
         this.totalCompanies = data.Record_Count;
         if(this.$route.query.compId){
           this.getTargetCompaniesDetails(this.$route.query.compId);
         }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        });
    },
    editRow(index){
      this.editRowIndex = index;
      this.companyDetailsFields[index].disableField = false;
    },
    async validateCompanyDetails(){
     let inputFields = this.companyDetailsFields;
      let appFieldPair = [];
      inputFields.forEach(element => {
        appFieldPair.push({"Key":element.App_API_Field_Name,"Value":element.App_Value});
      });
      let input = {
        "Connection_Id": this.connectionID.Key,
        "VisualVisitorID": this.VVID,
        "App_Field_Pair": appFieldPair
      };
      this.$vs.loading();
      await this.axios
        .post("/ws/TargetCompanyListDetail/ValidateRecord", input)
        .then((response) => {
          let data = response.data;
         if(data.Transaction_Successful){ 
          this.$vs.notify({
            title: "Success",
            text: data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
         } else {
             this.$vs.notify({
            title: "Erorr",
            text: data.Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
         }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        });
    },
    async updateCompanyDetails(){
      let inputFields = this.companyDetailsFields;
      let appFieldPair = [];
      inputFields.forEach(element => {
        appFieldPair.push({"Key":element.App_API_Field_Name,"Value":element.App_Value});
      });
      let input = {
        "VisualVisitorID": this.VVID,
        "App_Field_Pair": appFieldPair,
        "Connection_Id": this.connectionID.Key,
      };
      this.$vs.loading();
      await this.axios
        .post("/ws/TargetCompanyListDetail/UpdateRecord", input)
        .then((response) => {
          let data = response.data;
          if(data.Transaction_Successful){
            this.$vs.notify({
              title: "Success",
              text: data.Message,
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
            this.searchCompanies(1);
            this.cancelCompanyDetails();
          } else {
            this.$vs.notify({
            title: "Erorr",
            text: data.Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        });
    },
    cancelCompanyDetails(){
      this.companydetailsPopup = false;
      this.companyDetailsFields = [];
      this.systemName = null;
      this.targetComapnyName = null;
      this.VVID = null;
    },
    clearSearchCriteria(){
      this.crmFilterFields.forEach(element => {
        if(element.Search_Values){
          element.searchCompanyValue = [];
        } else {
          element.searchCompanyValue = null;
        }
      });
      this.systemFilterFields.forEach(element => {
        if(element.Search_Values){
          element.searchCompanyValue = [];
        } else {
          element.searchCompanyValue = null;
        }
      });
      this.tagsTempArr = [],
      this.tags = [],
      this.searchCompanies(1);
    },
    goToTargetContact(){
      this.companydetailsPopup=false;
      setTimeout(() => {
         this.$router.push({ name: 'TargetContactList', query: { conId: this.connectionID.Key, compId:this.VVID } })
      }, 100);
    },
    async syncNowCompanyDetails(){
      let inputFields = this.companyDetailsFields;
      let appFieldPair = [];
      inputFields.forEach(element => {
        appFieldPair.push({"Key":element.App_API_Field_Name,"Value":element.App_Value});
      });
      let input = {
        "VisualVisitorID": this.VVID,
        "App_Field_Pair": appFieldPair,
        "Connection_Id": this.connectionID.Key,
      };
      this.$vs.loading();
      await this.axios
        .post("/ws/TargetCompanyListDetail/SyncRecord", input)
        .then((response) => {
          let data = response.data;
          if(data.Transaction_Successful){
            this.$vs.notify({
              title: "Success",
              text: data.Message,
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
            this.searchCompanies(1);
            this.cancelCompanyDetails();
          } else {
            this.$vs.notify({
            title: "Erorr",
            text: data.Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        });
    },
  },
};
</script>